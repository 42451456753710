import React from "react";
import {Link} from "gatsby";
import Layout from "components/Layout";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";

const NotFoundPage: React.FC<PropsWithTranslationSheet> = ({pageContext: {t}}) => {
    return (
        <Layout
            t={t}
            title={`404 - ${t.PAGE_404_ERROR_TEXT}`}
            metaDescription={t.PAGE_404_ERROR_TEXT}
            noIndex
        >
            <div className={"prose m-auto pt-10"}>
                <h1>{t.PAGE_404_ERROR_TEXT}</h1>

                <Link to="/">{t.GO_HOME_TEXT}</Link>
            </div>
        </Layout>
    );
};

export default NotFoundPage;
